import React, { useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import "./ROICalc.css";

const TollGuruCalculator: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [results, setResults] = useState<{
    spent: string;
    tollGuruCost: string;
    tollGuruSavings: string;
    roiSavingPercent: string;
    monthlySpend: Number;
    fte: Number;
    fteHours: Number;
  } | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [fteHours, setFteHours] = useState(30);

  const formatNumbers = (number: number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const showError = (element: HTMLInputElement, message: string) => {
    element.classList.add("error");
    const errorDiv = document.createElement("div");
    errorDiv.textContent = message;
    errorDiv.className = "text-red-500 text-sm mt-0";
    element.parentNode?.parentNode?.insertBefore(
      errorDiv,
      element.parentNode.nextSibling
    );
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!formRef.current) return;

    const monthlySpend = formRef.current["monthly-spend"] as HTMLInputElement;
    const vehicles = formRef.current["vehicles"] as HTMLInputElement;
    const vehiclesOnToll = formRef.current[
      "vehicles-on-toll"
    ] as HTMLInputElement;
    const fteinput = formRef.current["fte"] as HTMLInputElement;
    const vehicleType = formRef.current["vehicle-type"] as HTMLSelectElement;
    const avoidTolls = formRef.current["avoid-tolls"] as HTMLInputElement;
    const fteHours = formRef.current["fte-hours"] as HTMLInputElement;

    let isValid = true;

    // Clear previous errors
    document.querySelectorAll(".text-red-500").forEach((errorDiv) => {
      errorDiv.parentNode?.removeChild(errorDiv);
    });
    document.querySelectorAll(".error").forEach((element) => {
      element.classList.remove("error");
    });

    if (!monthlySpend.value || Number(monthlySpend.value) < 0) {
      showError(monthlySpend, "Please enter a valid monthly spend.");
      isValid = false;
    }

    if (!vehicles.value || Number(vehicles.value) < 0) {
      showError(vehicles, "Please enter a valid number of vehicles.");
      isValid = false;
    }

    if (
      !vehiclesOnToll.value ||
      Number(vehiclesOnToll.value) < 0 ||
      Number(vehicles.value) < Number(vehiclesOnToll.value)
    ) {
      showError(
        vehiclesOnToll,
        "Please enter a valid number of vehicles on toll roads."
      );
      isValid = false;
    }

    if (!fteinput.value || Number(fteinput.value) < 0) {
      showError(fteinput, "Please enter a valid FTE count.");
      isValid = false;
    }

    if (isValid) {
      const data = {
        monthlySpend: Number(monthlySpend.value),
        vehicles: Number(vehicles.value),
        vehiclesOnToll: Number(vehiclesOnToll.value),
        vehicleType: vehicleType.value,
        fte: Number(fteinput.value),
        avoidTolls: avoidTolls.checked,
        fteHours: Number(fteHours.value),
      };

      const FTE = data.fteHours * data.fte * 58 * 12;
      const TOLL = 12 * data.monthlySpend;

      const resultValues = {
        monthlySpend: data.monthlySpend,
        fte: data.fte,
        fteHours: data.fteHours,
        spent: "$" + formatNumbers(FTE + TOLL),
        tollGuruCost: "$" + formatNumbers(100 * data.vehiclesOnToll),
        tollGuruSavings: "$" + formatNumbers(FTE + TOLL * 0.14),
        roiSavingPercent:
          formatNumbers(
            ((FTE + TOLL * 0.14 - 100 * data.vehiclesOnToll) /
              (100 * data.vehiclesOnToll)) *
              100
          ) + "%",
      };

      setResults(resultValues);
      setModalVisible(true);
    }
  };

  return (
    <div className="w-full drop-shadow-[0_0px_10px_rgba(0,0,0,0.1)] bg-white shadow-xl p-8 pt-4 rounded-md relative transition-all duration-300 transform hover:scale-105 hover:shadow-deep-glow">
      <h2
        tabIndex={0}
        aria-label="Calculate Toll Savings with TollGuru"
        className="text-tollguru-heading text-xl font-bold tracking-tighter text-left">
        Calculate Toll Savings with TollGuru
      </h2>
      <form className="w-full mt-6" ref={formRef} onSubmit={handleSubmit}>
        <div className="relative mt-6 w-auto group">
          <input
            type="number"
            id="monthly-spend"
            name="monthly-spend"
            min="0"
            max="50000000"
            className="outline-none font-medium px-3 text-sm py-2 peer rounded-md w-full ring-0 border-0 text-tollguru-text bg-white focus:outline-none focus:ring-0 focus:border-0"
            placeholder=" "
          />
          <label
            htmlFor="monthly-spend"
            className="absolute left-[9px] top-px text-xs text-tollguru-text transition-all duration-300 px-1 transform -translate-y-1/2 pointer-events-none 
             peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-xl group-focus-within:!top-px group-focus-within:!text-xs group-focus-within:!text-tollguru-primary">
            Monthly toll spend ($)
          </label>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] invisible peer-placeholder-shown:visible 
             group-focus-within:border-tollguru-primary group-focus-within:border-2 group-hover:border-tollguru-primary">
            <legend className="ml-2 px-0 text-xs transition-all duration-300 invisible max-w-[0.01px] group-focus-within:max-w-full group-focus-within:px-1 whitespace-nowrap">
              Monthly toll spend ($)
            </legend>
          </fieldset>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] visible peer-placeholder-shown:invisible 
             group-focus-within:border-2 group-focus-within:!border-tollguru-primary group-hover:border-tollguru-primary">
            <legend className="ml-2 text-xs invisible px-1 max-w-full whitespace-nowrap">
              Monthly toll spend ($)
            </legend>
          </fieldset>
        </div>

        <div className="relative mt-6 w-auto group">
          <input
            type="number"
            id="vehicles"
            name="vehicles"
            min="0"
            max="10000"
            className="outline-none px-3 font-medium text-sm py-2 peer rounded-md w-full ring-0 border-0 text-tollguru-text bg-white focus:outline-none focus:ring-0 focus:border-0"
            placeholder=" "
          />
          <label
            htmlFor="vehicles"
            className="absolute left-[9px] top-px text-xs text-tollguru-text transition-all duration-300 px-1 transform -translate-y-1/2 pointer-events-none 
             peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-xl group-focus-within:!top-px group-focus-within:!text-xs group-focus-within:!text-tollguru-primary">
            Total vehicles in fleet
          </label>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] invisible peer-placeholder-shown:visible 
             group-focus-within:border-tollguru-primary group-focus-within:border-2 group-hover:border-tollguru-primary">
            <legend className="ml-2 px-0 text-xs transition-all duration-300 invisible max-w-[0.01px] group-focus-within:max-w-full group-focus-within:px-1 whitespace-nowrap">
              Total vehicles in fleet
            </legend>
          </fieldset>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] visible peer-placeholder-shown:invisible 
             group-focus-within:border-2 group-focus-within:!border-tollguru-primary group-hover:border-tollguru-primary">
            <legend className="ml-2 text-xs invisible px-1 max-w-full whitespace-nowrap">
              Total vehicles in fleet
            </legend>
          </fieldset>
        </div>

        <div className="relative mt-6 w-auto group">
          <input
            type="number"
            id="vehicles-on-toll"
            name="vehicles-on-toll"
            min="0"
            max="10000"
            className="outline-none px-3 font-medium text-sm py-2 peer rounded-md w-full ring-0 border-0 text-tollguru-text bg-white focus:outline-none focus:ring-0 focus:border-0"
            placeholder=" "
          />
          <label
            htmlFor="vehicles-on-toll"
            className="absolute left-[9px] top-px text-xs text-tollguru-text transition-all duration-300 px-1 transform -translate-y-1/2 pointer-events-none 
             peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-xl group-focus-within:!top-px group-focus-within:!text-xs group-focus-within:!text-tollguru-primary">
            Vehicles that travel on toll roads
          </label>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] invisible peer-placeholder-shown:visible 
             group-focus-within:border-tollguru-primary group-focus-within:border-2 group-hover:border-tollguru-primary">
            <legend className="ml-2 px-0 text-xs transition-all duration-300 invisible max-w-[0.01px] group-focus-within:max-w-full group-focus-within:px-1 whitespace-nowrap">
              Vehicles that travel on toll roads
            </legend>
          </fieldset>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] visible peer-placeholder-shown:invisible 
             group-focus-within:border-2 group-focus-within:!border-tollguru-primary group-hover:border-tollguru-primary">
            <legend className="ml-2 text-xs invisible px-1 max-w-full whitespace-nowrap">
              Vehicles that travel on toll roads
            </legend>
          </fieldset>
        </div>

        <div className="relative mt-6 w-auto group">
          <select
            id="vehicle-type"
            name="vehicle-type"
            className="outline-none px-3 text-sm font-medium py-2 peer rounded-md w-full ring-0 border-0 text-tollguru-text bg-white focus:outline-none focus:ring-0 focus:border-0">
            <option value="trucks">Trucks</option>
            <option value="car">Car</option>
          </select>
          <label
            htmlFor="vehicles-on-toll"
            className="absolute left-[9px] top-px text-xs text-tollguru-text transition-all duration-300 px-1 transform -translate-y-1/2 pointer-events-none 
             peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-xl group-focus-within:!top-px group-focus-within:!text-xs group-focus-within:!text-tollguru-primary">
            Vehicles type
          </label>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] invisible peer-placeholder-shown:visible 
             group-focus-within:border-tollguru-primary group-focus-within:border-2 group-hover:border-tollguru-primary">
            <legend className="ml-2 px-0 text-xs transition-all duration-300 invisible max-w-[0.01px] group-focus-within:max-w-full group-focus-within:px-1 whitespace-nowrap">
              Vehicles type
            </legend>
          </fieldset>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] visible peer-placeholder-shown:invisible 
             group-focus-within:border-2 group-focus-within:!border-tollguru-primary group-hover:border-tollguru-primary">
            <legend className="ml-2 text-xs invisible px-1 max-w-full whitespace-nowrap">
              Vehicles type
            </legend>
          </fieldset>
        </div>

        {/* <div className="flex gap-4 mt-5">
                    <label htmlFor="avoid-tolls" className="block mb-2 text-sm font-medium text-tollguru-text">Is avoiding toll roads an option for you?</label>
                    <input type="checkbox" id="avoid-tolls" name="avoid-tolls"
                        className="form-checkbox h-5 w-5 text-tollguru-primary rounded-md focus:outline-none focus:ring-0" />
                </div> */}

        <div className="relative mt-6 w-auto group">
          <select
            id="avoid-tolls"
            name="avoid-tolls"
            className="outline-none px-3 text-sm font-medium py-2 peer rounded-md w-full ring-0 border-0 text-tollguru-text bg-white focus:outline-none focus:ring-0 focus:border-0">
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          <label
            htmlFor="avoid-tolls"
            className="absolute left-[9px] top-px text-xs text-tollguru-text transition-all duration-300 px-1 transform -translate-y-1/2 pointer-events-none 
        peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-xl group-focus-within:!top-px group-focus-within:!text-xs group-focus-within:!text-tollguru-primary">
            Is avoiding toll roads an option for you?
          </label>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] invisible peer-placeholder-shown:visible 
        group-focus-within:border-tollguru-primary group-focus-within:border-2 group-hover:border-tollguru-primary">
            <legend className="ml-2 px-0 text-xs transition-all duration-300 invisible max-w-[0.01px] group-focus-within:max-w-full group-focus-within:px-1 whitespace-nowrap">
              Is avoiding toll roads an option for you?
            </legend>
          </fieldset>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] visible peer-placeholder-shown:invisible 
        group-focus-within:border-2 group-focus-within:!border-tollguru-primary group-hover:border-tollguru-primary">
            <legend className="ml-2 text-xs invisible px-1 max-w-full whitespace-nowrap">
              Is avoiding toll roads an option for you?
            </legend>
          </fieldset>
        </div>

        <div className="relative mt-4 w-auto group">
          <input
            type="number"
            id="fte"
            name="fte"
            min="0"
            max="1000"
            className="outline-none px-3 font-medium text-sm py-2 peer rounded-md w-full ring-0 border-0 text-tollguru-text bg-white focus:outline-none focus:ring-0 focus:border-0"
            placeholder=" "
          />
          <label
            htmlFor="fte"
            className="absolute left-[9px] top-px text-xs text-tollguru-text transition-all duration-300 px-1 transform -translate-y-1/2 pointer-events-none 
           peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-xl group-focus-within:!top-px group-focus-within:!text-xs group-focus-within:!text-tollguru-primary">
            FTEs working on routing
          </label>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] invisible peer-placeholder-shown:visible 
           group-focus-within:border-tollguru-primary group-focus-within:border-2 group-hover:border-tollguru-primary">
            <legend className="ml-2 px-0 text-xs transition-all duration-300 invisible max-w-[0.01px] group-focus-within:max-w-full group-focus-within:px-1 whitespace-nowrap">
              FTEs working on routing
            </legend>
          </fieldset>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] visible peer-placeholder-shown:invisible 
           group-focus-within:border-2 group-focus-within:!border-tollguru-primary group-hover:border-tollguru-primary">
            <legend className="ml-2 text-xs invisible px-1 max-w-full whitespace-nowrap">
              FTEs working on routing
            </legend>
          </fieldset>
        </div>
        <div className="relative mt-6 w-auto group">
          <input
            type="number"
            id="fte-hours"
            name="fte-hours"
            min="0"
            max="160"
            className="outline-none px-3 font-medium text-sm py-2 peer rounded-md w-full ring-0 border-0 text-tollguru-text bg-white focus:outline-none focus:ring-0 focus:border-0"
            value={fteHours}
            onChange={(e) => setFteHours(Number(e.target.value))}
          />
          <label
            htmlFor="fte-hours"
            className="absolute left-[9px] top-px text-xs text-tollguru-text transition-all duration-300 px-1 transform -translate-y-1/2 pointer-events-none 
        peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-xl group-focus-within:!top-px group-focus-within:!text-xs group-focus-within:!text-tollguru-primary">
            Hours/month FTE working on routing
          </label>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] invisible peer-placeholder-shown:visible 
        group-focus-within:border-tollguru-primary group-focus-within:border-2 group-hover:border-tollguru-primary">
            <legend className="ml-2 px-0 text-xs transition-all duration-300 invisible max-w-[0.01px] group-focus-within:max-w-full group-focus-within:px-1 whitespace-nowrap">
              Hours/month FTE working on routing
            </legend>
          </fieldset>
          <fieldset
            className="inset-0 absolute border border-gray-700 rounded-md pointer-events-none mt-[-9px] visible peer-placeholder-shown:invisible 
        group-focus-within:border-2 group-focus-within:!border-tollguru-primary group-hover:border-tollguru-primary">
            <legend className="ml-2 text-xs invisible px-1 max-w-full whitespace-nowrap">
              Hours/month FTE working on routing
            </legend>
          </fieldset>
        </div>
        {/* <div className="mt-4">
                    <label htmlFor="fte-hours" className="block mb-2 text-sm font-medium text-tollguru-text">Hours/month FTE working on routing</label>
                    <input type="range" id="fte-hours" name="fte-hours" min="0" max="160" step="1" value="30"
                        className="w-full h-2 bg-blue-50 rounded-md appearance-none cursor-pointer text-tollguru-text"
                        oninput="this.nextElementSibling.value = this.value" />
                    <output className="text-sm text-tollguru-text px-3 py-1 font-medium">30</output>
                </div> */}
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className="text-center transition mt-3 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 focus-visible:outline-none focus-visible:shadow-outline-indigo rounded-md  px-7 py-2.5 bg-gradient-to-t from-tollguru-primary to-tollguru-secondary text-white hover:bg-tollguru-secondary flex gap-1 items-center justify-center">
            Calculate Savings
          </button>
        </div>
      </form>

      {modalVisible && (
        <div
          id="resultsModal"
          className="absolute inset-0 flex items-center justify-center rounded-md backdrop-blur-sm bg-[rgba(0,0,0,0.1)]">
          <div className="bg-white px-6 py-8 rounded-md max-w-lg lg:max-w-2xl lg:w-[180%] mx-4 relative lg:absolute lg:right-0 lg:z-10">
            <button
              id="close-roi-modal"
              className="absolute top-3 right-3 text-md ring-0 bg-white"
              onClick={closeModal}>
              <RxCross2 color="red" />
            </button>
            <div id="modalResultsContent" className="space-y-2">
              {results && (
                <>
                  <h3 className="text-lg font-bold text-transparent bg-clip-text bg-text-gradient mb-4">
                    Your 1-year ROI with TollGuru is {results?.roiSavingPercent}
                  </h3>
                  <div className="flex justify-between pt-1">
                    <div className="text-tollguru-text">
                      Tolls paid annually
                    </div>
                    <div>
                      ${formatNumbers(12 * Number(results?.monthlySpend))}
                    </div>
                  </div>
                  <div className="flex justify-between pt-1">
                    <div className="text-tollguru-text">
                      Annual toll management spend
                    </div>
                    <div>
                      $
                      {formatNumbers(
                        Number(results?.fte) *
                          Number(results?.fteHours) *
                          58 *
                          12
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between pt-1">
                    <div className="text-tollguru-text">
                      Total annual spend in tolls
                    </div>
                    <div>{results?.spent}</div>
                  </div>
                  <div className="flex justify-between pt-1">
                    <div className="text-tollguru-text">
                      TollGuru annual subscription cost
                    </div>
                    <div>{results?.tollGuruCost}</div>
                  </div>
                  <div className="flex justify-between pt-1">
                    <div className="text-tollguru-text font-bold">
                      Savings with TollGuru
                    </div>
                    <div className="font-bold">{results?.tollGuruSavings}</div>
                  </div>
                </>
              )}
            </div>
            <div className="mt-6 lg:mt-10">
              <a
                className="text-center transition focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 focus-visible:outline-none focus-visible:shadow-outline-indigo rounded-md px-4 py-2 border-tollguru-gradient bg-gradient"
                href="https://tollguru.com/toll-calculator">
                <span className="bg-text-gradient text-transparent bg-clip-text">
                  Start Saving with TollGuru »
                </span>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TollGuruCalculator;
